<!-- routify:options title='Home' -->
<!-- routify:options index=1 -->
<!-- routify:options showInNavigation=true -->

<script>
    import { t, storeHashesAreValid } from 'totalinvoice-frontend-core'

    const showClearStorageNotification = !storeHashesAreValid()
</script>

<style>
    .header-margin {
        margin-top: -3em;
    }
    .hero-body {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
    }</style>

<section class="hero" class:header-margin={!showClearStorageNotification}>
    <div class="hero-body">
        <figure class="image">
            <img src="/images/header_20211014.jpeg" alt="Header">
        </figure>
    </div>
</section>

<h1 class="title">{$t('TotalEnergies Invoicing Platform')}</h1>

<div class="block">
    {$t('Dear Customer,')}
</div>
<div class="block">
    {$t('TotalEnergies Belgium now offers you, through this site, a unique tool for editing and receiving in a personalized way your invoices which are linked to your purchases made at our service stations.')}
</div>
<div class="block">
    {$t('To edit and receive an invoice corresponding to your purchases made in stations, all you need to do is bring your ticket: you will be asked to provide the number, amount, date and name of the station. These references allow us to retrieve the data that will be the subject of an invoice to you. The procedure for editing your invoice is made simple and intuitive, let yourself be guided!')}
</div>
<div class="block">
    {$t('If, however, the station in which you made your purchases was not in the list of stations offered, we invite you to contact the manager of this station directly, who will be happy to produce an invoice for you that corresponds to your purchases.')}
</div>
<div class="block">
    {$t('Finally, if you want to know the absolute ease of billing, we suggest you opt for a Total fuel card. To do this, simply click on the link below:')}
    <br>
    <a href="{$t('link_opt_total_card_href')}" target="_blank">
        {$t('link_opt_total_card_label')}
    </a>
</div>
<div class="block">
    {$t('TotalEnergies Belgium and your station thank you for your trust.')}
</div>
