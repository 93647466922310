<!-- routify:options title='Address list' -->
<!-- routify:options index=1 -->
<!-- routify:options showInNavigation=true -->

<script>
    import { onMount } from 'svelte'
    import { customerStore } from '../../../stores/Customer'
    import { fetchJSON } from '../../../common/Backend'
    import { goto } from '@sveltech/routify'
    import { t } from "totalinvoice-frontend-core"
    import { Button, RefreshButton, DetailsButton } from "totalinvoice-frontend-core"
    import { ServerErrorNotification, DelayedSpinner } from 'totalinvoice-frontend-core'
    import { STATUS_NONE, STATUS_LOADING, STATUS_SERVER_ERROR } from 'totalinvoice-frontend-core'
    import { CUSTOMER_TITLE_VALUE_OTHER } from 'totalinvoice-frontend-core'

    let status = STATUS_LOADING

    let spinSyncButton = true

    let error = ''
    let showServerErrorNotification = false
    $: showServerErrorNotification = status === STATUS_SERVER_ERROR

    onMount(async () => {
        loadData()
    })

    async function loadData() {
        status = STATUS_LOADING
        spinSyncButton = true
        const res = await fetchJSON('customer/context/list')
        if (res.status === 'ok') {
            customerStore.update(s => ({ ...s, customerContext: res.data }))
            status = STATUS_NONE

            // Data is loaded too fast to see the spinning icon of the refresh button,
            // so we add a short delay to make sure that the user gets a visible feedback.
            setTimeout(() => spinSyncButton = false, 1000)
        } else {
            error = res
            spinSyncButton = false
            status = STATUS_SERVER_ERROR
        }
    }
</script>

<style>
    table tr td {
        vertical-align: middle;
    }</style>

<h2 class="title is-2 is-spaced page-title">{$t('Address list')}</h2>

{#if showServerErrorNotification}
    <ServerErrorNotification bind:active={showServerErrorNotification} {error} />
{/if}

<div class="buttons">
    <Button type='is-link' iconLeft='fas fa-plus' on:click={() => $goto('../create')}>
        {$t('Create a new address')}
    </Button>
    <RefreshButton refreshing={spinSyncButton} on:click={() => loadData()} />
</div>

{#if status === STATUS_LOADING}
    <div class="has-text-centered">
        <DelayedSpinner />
    </div>
{:else}
    {#if $customerStore.customerContext && $customerStore.customerContext.length > 0}
        <table class="table is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>{$t('Civility / Type')}</th>
                    <th>{$t('Name')}</th>
                    <th>{$t('Address')}</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {#each $customerStore.customerContext as { customer }}
                    <tr>
                        <td>{customer.title === CUSTOMER_TITLE_VALUE_OTHER ? $t('customer_title_0') : customer.title}</td>
                        <td>
                            {#if customer.isprivate}
                                {`${customer.first_name} `}
                            {/if}
                            {customer.last_name}
                        </td>
                        <td>{`${customer.street}, ${customer.zip_code} ${customer.city}, ${customer.country}`}</td>
                        <td class="is-narrow">
                            <DetailsButton on:click={() => $goto(`../${customer.id}`)} />
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    {:else}
        <div class="has-text-centered pt-5">
            <p>
                <span class="icon is-large has-text-grey-light">
                    <i class="fas fa-building fa-5x" />
                </span>
            </p>
            <h5 class="subtitle is-5 mt-3">{$t('No addresses found')}</h5>
        </div>
    {/if}
{/if}
