<!-- routify:options title='Address details' -->
<!-- routify:options index=3 -->
<!-- routify:options showInNavigation=false -->

<script>
    import { params, url, goto } from '@sveltech/routify'
    import { onMount } from 'svelte'
    import { customerStore } from '../../../stores/Customer'
    import { fetchJSON } from '../../../common/Backend'
    import { scrollToTop, requestDelay } from '../../../common/Utils'
    import E404 from '../../_fallback.svelte'
    import CustomerForm from '../../../components/CustomerForm.svelte'
    import { Notification, ServerErrorNotification, InvalidFieldsNotification } from 'totalinvoice-frontend-core'
    import { Box, AssignmentList, DelayedSpinner } from 'totalinvoice-frontend-core'
    import { BackButton, EditButton, CancelButton, SaveButton, DeleteButton } from 'totalinvoice-frontend-core'
    import { t, dt, languageStore, dateStringOptionsLong } from 'totalinvoice-frontend-core'
    import { STATUS_NONE, STATUS_LOADING, STATUS_PROCESSING, STATUS_INVALID_FIELDS, STATUS_SERVER_ERROR, STATUS_SUCCESS } from 'totalinvoice-frontend-core'
    import { getCustomerCheckErrors } from "../../../common/Service"

    let status = STATUS_NONE

    let customerForm
    let customerCtxList = null
    let customerCtx = null
    let customerBackup = null

    let showFieldErrors = false
    $: showFieldErrors = status === STATUS_INVALID_FIELDS

    let allFieldsAreValid = false
    let showInvalidFieldsNotification = false
    $: showInvalidFieldsNotification = showFieldErrors && status === STATUS_INVALID_FIELDS

    let error = ''
    let showServerErrorNotification = false
    $: showServerErrorNotification = status === STATUS_SERVER_ERROR

    let showSuccessNotification = false
    $: showSuccessNotification = status === STATUS_SUCCESS

    let checkedErrors = {}

    let customerIsEditable = false
    let updatingCustomer = false
    let deletingCustomer = false

    let assignmentList = []

    onMount(async () => {
        loadData()
    })

    async function loadData() {
        status = STATUS_LOADING
        const customerId = $params.id
        if (customerId !== null) {
            customerCtxList = $customerStore.customerContext

            // works only if the user visited the 'address list' page at least once
            // otherwise the  customer store is empty
            // TODO initialize the customer store or load single customer on demand
            await requestDelay()
            customerCtx = customerCtxList
                .filter(ctx => ctx.customer.id == customerId)
                .pop()

            if (customerCtx) {
                if (customerCtx.isOwner) {
                    let res = await fetchJSON(`customer/assignments/${customerId}`)
                    if (res.status === 'ok') {
                        assignmentList = res.data
                        status = STATUS_NONE
                    } else {
                        error = res
                        status = STATUS_SERVER_ERROR
                    }
                } else {
                    status = STATUS_NONE
                }
            } else {
                customerCtx = null
                status = STATUS_NONE
            }
        } else {
            customerCtx = null
            status = STATUS_NONE
        }
    }

    function editCustomer() {
        customerBackup = { ...customerCtx.customer }
        customerIsEditable = true
    }

    function cancelEditCustomer() {
        customerIsEditable = false
        // TODO stop a running update request (or disable/hide the button while the request is running)
        customerCtx.customer = customerBackup
        checkedErrors = {}
        status = STATUS_NONE
    }

    async function updateCustomer() {
        status = STATUS_PROCESSING
        updatingCustomer = true
        checkedErrors = await getCustomerCheckErrors(customerCtx.customer, $languageStore.selected)
        if (checkedErrors == STATUS_SERVER_ERROR) {
            status = STATUS_SERVER_ERROR
            checkedErrors = {}
        } else {
            if (allFieldsAreValid && Object.keys(checkedErrors).length == 0) {
                const res = await fetchJSON('customer/update/', customerCtx.customer)
                if (res.status === 'ok') {
                    customerStore.update(s => ({ ...s, customerContext: customerCtxList }))
                    status = STATUS_SUCCESS
                } else {
                    error = res
                    status = STATUS_SERVER_ERROR
                }
                customerIsEditable = false
            } else {
                customerForm.setErrors(checkedErrors)
                status = STATUS_INVALID_FIELDS
            }
        }
        updatingCustomer = false
        scrollToTop()
    }

    async function deleteCustomer() {
        let confirmDelete = confirm($t('Are you sure to delete this address?')) // TODO use a modal
        if (confirmDelete) {
            deletingCustomer = true
            const res = await fetchJSON(`customer/delete/${customerCtx.customer.id}`)
            if (res.status === 'ok') {
                status = STATUS_NONE
                $goto('../list')
            } else {
                error = res
                status = STATUS_SERVER_ERROR
            }
            deletingCustomer = false
        }
    }
</script>

<style>
    .flex-grow {
        flex-grow: 1
    }</style>

<h2 class="title is-2 is-spaced page-title">{$t('Address details')}</h2>

{#if showServerErrorNotification}
    <ServerErrorNotification bind:active={showServerErrorNotification} {error} />
{/if}

{#if showInvalidFieldsNotification}
    <InvalidFieldsNotification bind:active={showInvalidFieldsNotification} errors={checkedErrors} />
{/if}

{#if showSuccessNotification}
    <Notification type='is-success' bind:active={showSuccessNotification} icon={true}>
        <strong>{$t('Address updated successfully.')}</strong>
    </Notification>
{/if}

{#if status === STATUS_LOADING}
    <div class="has-text-centered">
        <DelayedSpinner />
    </div>
{:else}
    {#if customerCtx !== null}
        <div class="columns is-multiline">
            <div class="column is-full-tablet is-half-desktop">
                <Box title={$t('Address')} icon='fas fa-building'>
                    <div class="flex-grow">
                        <CustomerForm bind:this={customerForm} customer={customerCtx.customer} editable={customerIsEditable}
                                bind:showErrors={showFieldErrors} bind:allFieldsAreValid={allFieldsAreValid} />
                    </div>
                    {#if customerCtx.isOwner}
                        <div class="buttons is-right mt-5">
                            {#if customerIsEditable}
                                <CancelButton on:click={cancelEditCustomer} />
                                <SaveButton loading={updatingCustomer} on:click={updateCustomer} />
                            {:else}
                                <EditButton on:click={editCustomer} />
                            {/if}
                        </div>
                    {/if}
                </Box>
            </div>

            {#if customerCtx.isOwner}
                <div class="column is-full-tablet is-half-desktop">
                    <Box title={$t('Assignments')} icon='fas fa-hospital-user'>
                        <div class="flex-grow">
                            <AssignmentList {assignmentList} searchable={false} sortable={false} />
                        </div>
                        <div class="buttons is-right mt-5">
                            <EditButton on:click={() => $goto(`/customer/assignments/${customerCtx.customer.id}`)} />
                        </div>
                    </Box>
                </div>
            {/if}

            {#if customerCtx.isOwner}
                <div class="column is-full-tablet is-half-desktop">
                    <Box title={$t('Administration')} icon='fas fa-cog'>
                        <div class="buttons">
                            <DeleteButton loading={deletingCustomer} on:click={deleteCustomer} />
                        </div>
                    </Box>
                </div>
            {/if}

        </div>
    {:else}
        <E404 />
    {/if}
{/if}

<div class="buttons mt-6">
    <BackButton on:click={() => $goto('../list')} />
</div>
