<!-- routify:options title='Invoice request list' -->
<!-- routify:options index=1 -->
<!-- routify:options showInNavigation=true -->

<script>
    import { onMount } from 'svelte'
    import { invoiceRequestStore } from '../../../stores/InvoiceRequest'
    import { fetchJSON } from '../../../common/Backend'
    import { statusMessage } from '../../../common/Utils'
    import { url, goto } from '@sveltech/routify'
    import { customerStore } from '../../../stores/Customer'
    import { t, dt } from 'totalinvoice-frontend-core'
    import { Button, RefreshButton, DetailsButton } from 'totalinvoice-frontend-core'
    import { ServerErrorNotification, DelayedSpinner } from 'totalinvoice-frontend-core'
    import { STATUS_NONE, STATUS_LOADING, STATUS_SERVER_ERROR } from 'totalinvoice-frontend-core'

    let status = STATUS_LOADING

    let spinSyncButton = true

    let error = ''
    let showServerErrorNotification = false
    $: showServerErrorNotification = status === STATUS_SERVER_ERROR

    onMount(async () => {
        loadData()
    })

    async function loadData() {
        status = STATUS_LOADING
        spinSyncButton = true
        const res = await fetchJSON('invoice-request/list')
        if (res.status === 'ok') {
            res.data.sort((a, b) => new Date(b.created) - new Date(a.created)) // sort by date DESC
            invoiceRequestStore.update(s => ({ ...s, invoiceRequestList: res.data }))
            status = STATUS_NONE

            // Data is loaded too fast to see the spinning icon of the refresh button,
            // so we add a short delay to make sure that the user gets a visible feedback.
            setTimeout(() => spinSyncButton = false, 1000)
        } else {
            error = res
            spinSyncButton = false
            status = STATUS_SERVER_ERROR
        }
    }
</script>

<style>
    table tr td {
        vertical-align: middle;
    }</style>

<h2 class="title is-2 is-spaced page-title">{$t('Invoice Request List')}</h2>

{#if showServerErrorNotification}
    <ServerErrorNotification bind:active={showServerErrorNotification} {error} />
{/if}

<div class="buttons">
    <Button type='is-link' iconLeft='fas fa-plus' on:click={() => $goto('../create')}>
        {$t('Request an invoice')}
    </Button>
    <RefreshButton refreshing={spinSyncButton} on:click={() => loadData()} />
</div>

{#if status === STATUS_LOADING}
    <div class="has-text-centered">
        <DelayedSpinner />
    </div>
{:else}
    {#if $invoiceRequestStore.invoiceRequestList && $invoiceRequestStore.invoiceRequestList.length > 0}
        <table class="table is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>{$t('Address')}</th>
                    <th>{$t('Number of receipts')}</th>
                    <th>{$t('Date')}</th>
                    <th>{$t('Status')}</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {#each $invoiceRequestStore.invoiceRequestList as invoice}
                    <tr>
                        <td>
                            {#if invoice.customerInfo.isprivate}
                                {invoice.customerInfo ? `${invoice.customerInfo.title} ${invoice.customerInfo.first_name} ${invoice.customerInfo.last_name}` : null}
                            {:else}
                                {invoice.customerInfo ? invoice.customerInfo.last_name : null}
                            {/if}
                        </td>
                        <td>{invoice.receiptList ? invoice.receiptList.length : null}</td>
                        <td>{$dt(new Date(invoice.created))}</td>
                        <td>
                            <div class="flex-align-middle">
                                {#if statusMessage[invoice.status]}
                                    {@html `<span class="icon is-medium mr-1"><i class="${statusMessage[invoice.status].icon} fa-lg" aria-hidden="true" /></span>`}
                                    <span>{$t(statusMessage[invoice.status].label)}</span>
                                {:else}
                                    <span class="icon is-medium mr-1"><i class="fas fa-times-circle fa-lg" aria-hidden="true" /></span>
                                    <span>{$t('Failed')}</span>
                                {/if}
                            </div>
                        </td>
                        <td class="is-narrow">
                            <DetailsButton on:click={() => $goto(`../${invoice.id}`)} />
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    {:else}
        <div class="has-text-centered pt-5">
            <p>
                <span class="icon is-large has-text-grey-light">
                    <i class="fas fa-file-invoice fa-5x" />
                </span>
            </p>
            <h5 class="subtitle is-5 mt-3">{$t('No invoice requests found')}</h5>
        </div>
    {/if}
{/if}
