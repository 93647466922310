<script>
    import { onMount } from "svelte"
    import { fetchJSON } from "../common/Backend"
    import { siteStore } from "../stores/Site"
    import { Button, FormNotice, ServerErrorNotification } from 'totalinvoice-frontend-core'
    import { t, dt } from 'totalinvoice-frontend-core'
    import { createFieldValidator, required } from 'totalinvoice-frontend-core'
    import AutoComplete from "simple-svelte-autocomplete"
    import CustomDatePicker from './CustomDatePicker.svelte'
    import TicketHelperModal from "./TicketHelperModal.svelte"

    export let receipts = []
    export let previousMonthPossible = true
    export let baseReceipt = {}

    export let editable = true

    let baseReceiptIsEmpty = false
    $: baseReceiptIsEmpty = !(baseReceipt.site_no || baseReceipt.receipt_no || baseReceipt.amount || baseReceipt.date)

    let validateCheck = false
    $: validateCheck = !(baseReceipt.site_no && baseReceipt.receipt_no && baseReceipt.amount && baseReceipt.date)

    let error = "";
    let showServerErrorNotification = false
    let showTicketHelperModal = false

    const [ siteFieldValidity, siteFieldValidate ] = createFieldValidator(required('Petrol station is required'))
    const [ receiptNumberFieldValidity, receiptNumberFieldValidate ] = createFieldValidator(required('Receipt number is required'))
    const [ amountFieldValidity, amountFieldValidate ] = createFieldValidator(required('Amount is required'))
    const [ dateFieldValidity, dateFieldValidate ] = createFieldValidator(required('Date is required'))

    export let allFieldsAreValid = false
    $: allFieldsAreValid = $siteFieldValidity.valid && $receiptNumberFieldValidity.valid && $amountFieldValidity.valid

    let selectedSite = null
    $: baseReceipt.site_no = selectedSite ? selectedSite.site_no : null

    onMount(async () => {
        if (editable) {
            refreshSiteList()
        }
    })

    function parseReceiptDate(dateValue) {
        const dateString = `${dateValue}` // YYYYMMDD
        return new Date(
            dateString.substring(0, 4),
            dateString.substring(4, 6) - 1,
            dateString.substring(6)
        )
    }

    function writeIndex(receipts) {
        return receipts.map((receipt, index) => {
            receipt.index = index + 1
            return receipt
        })
    }

    function removeReceipt(index) {
        receipts.splice(index, 1)
        receipts = writeIndex(receipts)
        checkReceiptDates()        
    }

    function clearBaseReceipt() {
        baseReceipt = {}
        selectedSite = null
    }

    async function refreshSiteList() {
        const res = await fetchJSON("site/list")
        if (res.status === "ok") {
            siteStore.update(s => ({ ...s, siteList: res.data }))
        } else {
            // TODO show error notification
        }
    }

    async function checkReceipt() {
        showServerErrorNotification = false
        error = ""

        baseReceipt.index = 1
        baseReceipt.status = "loading"

        const dateBackup = baseReceipt.date

        let formated =""
        let parts = baseReceipt.date.match(/([0-9]{2})\/([0-9]{2})\/([0-9]{4})/);
        formated = parts[3]+parts[2]+parts[1]
        baseReceipt.date = formated

        const today = new Date();
        if(parts[3] == today.getFullYear() && parts[2] >= today.getMonth()){
            previousMonthPossible = false
        }

        const res = await fetchJSON("receipt/status", { receipt: [baseReceipt] })
        if (res.status === "ok") {
            const statusList = res.data.statusList
            const r = statusList[0]

            if (r.status === "ok") {
                baseReceipt.status = "ok"
                baseReceipt.id = r.id

                receipts.push(baseReceipt)
                receipts = writeIndex(receipts)

                baseReceipt = {}
            } else {
                baseReceipt.date = dateBackup
                baseReceipt.status = null
                error = { message: $t(r.status) }
                showServerErrorNotification = true
            }
        } else {
            baseReceipt.date = dateBackup
            baseReceipt.status = null
            error = $t(res)
            showServerErrorNotification = true
        }
    }

    function checkReceiptDates(){
        const today = new Date();
        previousMonthPossible=true
        receipts.forEach((receipt)=>{
            let parts = receipt.date.match(/([0-9]{4})([0-9]{2})([0-9]{2})/);
             
            if(parts[1] == today.getFullYear() && parts[2] >= today.getMonth()){
                previousMonthPossible = false
            }
        })
    }

    function changeReceiptDate(value) {
        baseReceipt.date = value
    }

    function buildSiteLabel(site) {
        if (!site) return ''
        return (site.site_no + '').padStart(4, "0")
                + ' - ' + site.name.toLowerCase()
                + ', ' + site.address_1.toLowerCase()
                + ', ' + site.zip_code + ' ' + site.city.toLowerCase()
    }

    function buildSiteKeyword(site) {
        if (!site) return ''
        return (site.site_no + '').padStart(4, "0")
                + ' - ' + site.name.toLowerCase()
                + ', ' + site.address_1.toLowerCase()
                + ', ' + site.zip_code + ' ' + site.city.toLowerCase()
    }

    function displayReceiptDate(date){
        let parts = date.match(/([0-9]{4})([0-9]{2})([0-9]{2})/);
        return parts[3]+"/"+parts[2]+"/"+parts[1]
    }

</script>

<style>
    .site-no {
        width: 300px;
    }

    table tr.checked-rows td {
        vertical-align: middle;
    }</style>

{#if editable}
    <div class="">
        <table class="table is-striped is-fullwidth">
            <thead>
                <tr>
                    <th />
                    <th>
                        {$t('Petrol station')}
                        <Button type='is-text has-text-link ml-1'
                                iconLeft='fas fa-question-circle'
                                iconOnly={true}
                                noPadding={true}
                                on:click={() => showTicketHelperModal = true} />
                    </th>
                    <th>
                        {$t('Receipt number')}
                        <Button type='is-text has-text-link ml-1'
                                iconLeft='fas fa-question-circle'
                                iconOnly={true}
                                noPadding={true}
                                on:click={() => showTicketHelperModal = true} />
                    </th>
                    <th>{$t('Amount (Incl. Tax)')}</th>
                    <th>{$t('Date')}</th>
                    <th>{$t('Status')}</th>
                </tr>
            </thead>
            <tbody>
                {#each receipts as receipt, index}
                    <tr class="checked-rows">
                        <td class="is-narrow">
                            <Button type='is-text has-text-link'
                                    iconLeft='fas fa-trash fa-lg'
                                    iconOnly={true}
                                    on:click={() => removeReceipt(index)} />
                        </td>
                        <td>{receipt.site_no}</td>
                        <td>{receipt.receipt_no}</td>
                        <td>{receipt.amount}</td>
                        <td>{displayReceiptDate(receipt.date)}</td>
                        <td class="is-narrow">{receipt.status}</td>
                    </tr>
                {/each}
                <tr>
                    <td class="is-narrow">
                        <Button type='is-text has-text-link'
                                iconLeft='fas fa-trash fa-lg'
                                iconOnly={true}
                                disabled={baseReceiptIsEmpty}
                                on:click={() => clearBaseReceipt()} />
                    </td>
                    <td class="site-no">
                        <div class="control">
                            <AutoComplete
                                    items={$siteStore.siteList}
                                    bind:selectedItem={selectedSite}
                                    labelFunction={buildSiteLabel}
                                    keywordsFunction={buildSiteKeyword} />
                            <p class="help">{$t('Search by number, name or address')}</p>
                            <p class="help">{$t('If the station does not appear in this list, please contact directly the manager of the station where the purchase was made.')}</p>
                        </div>
                    </td>
                    <td class="receipt-no">
                        <div class="control">
                            <input class="input" type="number" min="0" bind:value={baseReceipt.receipt_no} />
                        </div>
                    </td>
                    <td class="amount">
                        <div class="control">
                            <input class="input" type="number" min="0" bind:value={baseReceipt.amount} />
                        </div>
                    </td>
                    <td>
                        <div class="control">
                            <CustomDatePicker id={'baseReceipt_date'} onchange={value => changeReceiptDate(value)} />
                        </div>
                    </td>
                    <td class="is-narrow">
                        <Button type='is-link'
                                loading={baseReceipt.status === 'loading'}
                                on:click={() => checkReceipt()}
                                disabled={validateCheck}>
                            {$t('Add')}
                        </Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <FormNotice />
        <TicketHelperModal bind:active={showTicketHelperModal} />
        {#if showServerErrorNotification}
            <ServerErrorNotification bind:active={showServerErrorNotification} {error} />
        {/if}
    </div>
{:else if receipts.length > 0}
    <div class="table-container">
        <table class="table is-bordered is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>{$t('Petrol station')}</th>
                    <th>{$t('Receipt number')}</th>
                    <th>{$t('Amount (Incl. Tax)')}</th>
                    <th>{$t('Date')}</th>
                </tr>
            </thead>
            <tbody>
                {#each receipts as receipt}
                    <tr>
                        <td>{receipt.site_no}</td>
                        <td>{receipt.receipt_no}</td>
                        <td>{receipt.amount}</td>
                        <td>{$dt(parseReceiptDate(receipt.date))}</td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </div>
{:else}
    <div class="has-text-centered pt-5">
      <p>
          <span class="icon is-large has-text-grey-light">
              <i class="fas fa-receipt fa-5x" />
          </span>
      </p>
      <h5 class="subtitle is-5 mt-3">{$t('No receipts found')}</h5>
  </div>
{/if}
